@use "sass:list";
@use "sass:math";

@import "ui-components/_variables.scss";
@import "ui-components/_mixins.scss";

/*
  [1] Inherit box-sizing instead of setting the as the global default. This
      makes it easier to use 3rd party modules written with content-box.

  [2] I dislike it when the cursor changes on text hover. This makes all
      elements inherit "cursor: default" from the html element, then we reapply
      the correct cursor for buttons, inputs and anchor links later.

  [3] In my opinion, relative makes a lot more sense as a default value than
      static.

  [4] Reapply cursor: pointer to all anchor links as it was removed at [2]. This
      also covers anchor links without a [href] attribute so let it stay even if
      you remove the cursor override.
*/

*,
::before,
::after {
  box-sizing: inherit;  /* [1] */
  cursor: inherit;      /* [2] */
  position: relative;   /* [3] */
}

html {
  box-sizing: border-box;
  cursor: default;
  height: 100%;
  font: 400 list.slash(size("font"), 1) var(--fontBody), var(--fontBodyFallback, #{font("fallback")});
  color: var(--text);
}

body {
  background: #fff;
  overflow-x: hidden;
  overflow-y: scroll;
  //@include fontSmoothing;
}

#root {
  max-width: 100%;
}

a {
  //@extend %commonTransition;
  color: var(--primary);
  text-decoration: none;
  touch-action: manipulation;
  -webkit-tap-highlight-color: rgba(#fff, 0);
  cursor: pointer; /* [4] */
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }

  &:focus {
    outline: 0;
  }

  &:active:hover {
    opacity: .5;
  }

  &[disabled] {
    cursor: not-allowed;
  }
}

svg,
img {
  width: auto;
  height: auto;
  max-width: 100%;
  display: inline-block;
  vertical-align: middle;
}

fieldset {
  border: none;
  padding: 0;
  margin: 0;
}

// mark {
//   background: color("primary");
// }

table {
  border-collapse: collapse;
  border-spacing: 0;
}

td,
th {
  padding: 0;
}

ul,
ol {
  margin: 0;
  padding-left: 0;
  line-height: 1;
  list-style: none;
}

textarea {
  resize: vertical;
}

picture {
  display: block;

  > video {
    display: none;
  }
}

// address {
//   line-height: font("line-height");
// }

button:focus {
  outline: none;
}

hr {
  border: none;
  border-bottom: 1px solid color("primary");
  @include vertical-margins(size("gutter--medium"));
}

[data-whatintent="keyboard"] :not(input):not([tabindex="-1"]):focus {
  box-shadow: inset 0 0 .5em color("primary");
}

input:not([type="button"]):not([type="submit"]), textarea {
  font-size: 16px;
}

[data-input-type="keyboard"] {
  :not(input):not(textarea):not([tabindex="-1"]):focus {
    //box-shadow: 0 0 0 3px color("primary");
    z-index: 1;
  }
}

// @TODO: Move into strip
:global {
  .apple-pay-button {
    height: 55px;
  }
  @supports (-webkit-appearance: -apple-pay-button) {
    .apple-pay-button { display: inline-block; -webkit-appearance: -apple-pay-button; }
    .apple-pay-button-black { -apple-pay-button-style: black; }
    .apple-pay-button-white { -apple-pay-button-style: white; }
    .apple-pay-button-white-with-line { -apple-pay-button-style: white-outline; }
  }

  @supports not (-webkit-appearance: -apple-pay-button) {
    .apple-pay-button {
      display: inline-block;
      background-size: 100% 60%;
      background-repeat: no-repeat;
      background-position: 50% 50%;
      border-radius: 5px;
      padding: 0px;
      box-sizing: border-box;
      min-width: 200px;
      min-height: 32px;
      max-height: 64px;
    }
    .apple-pay-button-black {
      background-image: -webkit-named-image(apple-pay-logo-white);
      background-color: black;
    }
    .apple-pay-button-white {
      background-image: -webkit-named-image(apple-pay-logo-black);
      background-color: white;
    }
    .apple-pay-button-white-with-line {
      background-image: -webkit-named-image(apple-pay-logo-black);
      background-color: white;
      border: .5px solid black;
    }
  }

  $height: 1rem + (size("input-padding") * 2);
  $inset: 1rem;

  .StripeElement {
    @extend %resetInput;
    display: block;
    width: 100%;
    font-size: max(16px * strip-unit(math.div(font("size"), 100)), 16px);
    margin-top: math.div(size("gutter--mini"), 2);
    padding-left:  $inset;
    padding-right: $inset;
    letter-spacing: inherit;
    background-color: #fff;
    // transition: $transition;
    font-weight: font("weight--bold");
    color: color("text--dark");
    border: 1px solid color("border");
    border-radius: size("border-radius");

    padding-top: 1.2rem;
    padding-bottom: 1.2rem;
  }

  .ElementsApp.Input {
    font-family: inherit !important;
  }

  .ElementsApp .InputElement.is-invalid {
    color: var(--negative);
  }

  .link {
    //@extend %commonTransition;
    color: var(--primary);
    text-decoration: none;
    touch-action: manipulation;
    -webkit-tap-highlight-color: rgba(#fff, 0);
    cursor: pointer; /* [4] */
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }

    &:focus {
      outline: 0;
    }

    &:active:hover {
      opacity: .5;
    }

    &[disabled] {
      cursor: not-allowed;
    }
  }

  .cms {
    section {
      margin-bottom: size("gutter--large");
    }

    &__columns {
      margin-top: size("gutter--large") * 1.5;

      h2,
      h3 {
        font-size: size("font--large");
        margin-bottom: size("gutter--small");
      }

      & > div:first-child {
        @media (min-width: breakpoint("small")) {
          flex: 1 1 auto;
          width: 72%;
          padding-right: size("gutter--large") * 2;
        }

        @media (max-width: (breakpoint("large") - 1px)) and (min-width: breakpoint("small")) {
          padding-right: size("gutter");
        }

        @media (max-width: (breakpoint("small") - 1px)) {
          margin-bottom: size("gutter--large") * 2;
        }
      }

      & > div:last-child {
        @media (min-width: breakpoint("small")) {
          flex: 0 0 auto;
          width: 333px;
          padding-left: size("gutter--large") * 2;
        }

        @media (max-width: (breakpoint("large") - 1px)) and (min-width: breakpoint("small")) {
          padding-left: size("gutter");
        }
      }

      @media (min-width: breakpoint("small")) {
        display: flex;
      }
    }

    &__accordion {
      border-top: 1px solid color("border");

      &:last-child {
        border-bottom: 1px solid color("border");
      }

      &Title {
        user-select: none;
        cursor: pointer;
        padding: size("gutter");
        padding-right: size("gutter--large") * 2.2;

        h3,
        h4 {
          pointer-events: none;
          font-size: size("font--small");
        }

        > h3,
        > h4 {
          max-width: 90%;
          color: color("text");
        }

        &::after {
          content: "";
          display: block;
          width: 24px;
          height: 24px;
          position: absolute;
          right: 17px;
          top: 17px;
          background-image: url("/assets/icons/chevron-down.svg");
          transition: transform transition("duration--organisms") transition("ease-ease");
        }

        &:hover {
          background-color: color("body--off");

          h4 {
            text-decoration: underline;
          }
        }
      }

      &Body {
        display: none;
        padding: size("gutter") size("gutter") size("gutter--large");
        font-size: size("font--small");
        line-height: font("line-height");
      }
    }

    &__sticky {
      @media (min-width: breakpoint("small")) {
        align-self: flex-start;
        position: sticky;
        top: size("header-height") + size("header-margin");
      }
    }

    &__ticket,
    &__call {
      background: #fff;
      padding: size("gutter");
      border: 1px solid color("border--muted");
      box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.24);
      border-radius: size("border-radius");

      h4 {
        font-size: size("font");
        color: color("text");
        font-weight: bold;
      }

      & > p,
      & > a {
        font-size: size("font--small");
      }

      button {
        width: 100%;
        border: none;
        background: color("primary");
        border-radius: size("border-radius");
        padding: size("gutter--small") size("gutter--large");
        font-size: size("font");
        letter-spacing: font("uc-letter-spacing");
        color: #fff;
        font-weight: bold;
      }
    }

    &__call {
      margin-top: size("gutter");
    }

    &__ticket .icon,
    &__call .icon {
      display: inline-block;
      background-size: cover;
      position: absolute;
      width: 32px;
      height: 32px;
      top: -6px;
      margin-left: size("gutter--mini");
    }

    &__ticket {
      .icon {
        background-image: url("/assets/chat.svg");
      }
    }

    &__call {
      .icon {
        background-image: url("/assets/phone.svg");
      }

      a {
        text-decoration: underline;
      }
    }
  }

  .open .cms__accordionTitle {
    background-color: color("body--off");

    h3 {
      font-weight: normal;
    }

    h4 {
      font-weight: bold;
      text-decoration: underline;
    }
  }

  .open .cms__accordionTitle::after {
    transform: rotate(180deg);
  }

  .open .cms__accordionBody {
    display: block;
  }

  .awardit-Iframe {
    position: relative;
    padding-bottom: 56.25%;
    margin-bottom: 20px;
  }
  
  .awardit-Iframe iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100% !important;
    height: 100% !important;
  }
}

